import { useState, useEffect } from 'react';
import CustomCountdown from '@/components/Countdown/Countdown';
import './css/task.css';

export default function Tasks() {
  const [deadline, setDeadline] = useState(getNextMidnight());
  function getNextMidnight() {
    const now = new Date();
    const nextMidnight = new Date(now);
    nextMidnight.setHours(24, 0, 0, 0);
    return nextMidnight.getTime();
  }
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      if (now >= deadline) {
        setDeadline(getNextMidnight());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [deadline]);
  const handleCountdownFinish = () => {
    console.log('Countdown finished');
    setDeadline(getNextMidnight());
  };
  return (
    <div>
      <div>
        <main className="w-full p-3 ">
          <div className="w-full">
            <div className="flex flex-col items-center text-sm text-[#d5c7fd]">
              <div>Tasks update every day</div>
              <div className="flex items-center">
                <div className="flex-shrink-0">Next update:</div>
                <div>
                  <CustomCountdown
                    deadline={deadline}
                    onFinish={handleCountdownFinish}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
