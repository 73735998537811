import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import userStore from '@/utils/hooks/userStore';
import gamesStore from '@/utils/hooks/gamesStore';
import WebApp from '@twa-dev/sdk';
import PageNavigator from '@/components/PageNavigator';
import indextop from '@/assets/indextop.png';
import indexspin from '@/assets/indexspin.png';
import indexgame from '@/assets/indexgame.png';
import arrow from '@/assets/arrow.png';
import spinicon from '@/assets/spinicon.png';
import bigtcat from '@/assets/big_tcat.gif';
import { Empty } from 'antd';

export default function Home({ handlePageChange }) {
  const [carouselData, setCarouselData] = useState([]);
  useEffect(() => {
    const fetchUserInfo = async () => {
      await userStore.fetchUserInfo();
      await gamesStore.fetchGameInfo({ daily_hot: true });
      setCarouselData(gamesStore.gameList);
    };

    fetchUserInfo();
  }, []);

  const handleButtonClick = (link) => {
    if (link.includes('t.me')) {
      WebApp.openTelegramLink(link);
    } else {
      WebApp.openLink(link, { try_instant_view: true });
    }
  };

  return (
    <div>
      <main>
        <div
          className="relative w-full h-[240px] text-white text-3xl font-semibold"
          style={{
            backgroundImage: `url(${indextop})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2">
            <p className="animated-text">Play for fun</p>
            <p className="animated-text mt-2">$TON and airdrop</p>
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center bg-cover bg-center">
          <div className="w-full flex flex-col items-center p-3">
            <div className="w-full  text-[white] flex justify-around items-center relative">
              <PageNavigator
                page="lucky"
                handlePageChange={handlePageChange}
                className="relative"
              >
                <img src={indexspin} alt="" />
                <img src={spinicon} alt="" className="spinicon" />
              </PageNavigator>
            </div>
            <div className="w-full mt-8 relative">
              {carouselData && carouselData.length > 0 ? (
                <div className="relative">
                  {carouselData.map((item) => (
                    <div
                      key={item.id}
                      className="relative h-full rounded-tl-2xl rounded-tr-lg"
                    >
                      <img
                        src={`${bigtcat}`}
                        className="w-full object-cover rounded-tl-2xl rounded-tr-lg"
                        alt="bigImage"
                      />
                      {/* <div className="absolute top-4 left-4 text-black font-bold text-3xl	">
                          MOST POPULAR <br />
                          TODAY
                        </div> */}
                    </div>
                  ))}
                  <div className="w-full h-16 bg-[#282b47] text-white rounded-bl-lg rounded-br-lg flex justify-between items-center p-3">
                    <div className="flex items-center">
                      <div className="w-10 h-10 bg-[#13142e] rounded-full">
                        <img src={indexgame} alt="" />
                      </div>
                      <div className="text-xl font-semibold ml-3">
                        <p>{carouselData[0].name}</p>
                        {/* <p>100 <span>player this month</span></p> */}
                      </div>
                    </div>
                    <div
                      className="w-[83px] h-[30px] bg-[#eeb435] flex items-center justify-center rounded-full"
                      onClick={() => handleButtonClick(carouselData[0].link)}
                    >
                      <p className="text-black text-base">Play</p>
                      <p>
                        <img src={arrow} className="w-[30px] h-[30px]" alt="" />
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

Home.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
};
