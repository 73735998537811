import { createRoot } from 'react-dom/client';
import WebApp from '@twa-dev/sdk';
import App from './App.jsx';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom'; // 引入 BrowserRouter

// import('eruda').then((eruda) => {
//   eruda.default.init();
// });

if (WebApp) {
  WebApp.ready();
  WebApp.expand();
  const webview = window.Telegram.WebView;
  webview.postEvent('web_app_setup_closing_behavior', false, {
    need_confirmation: true,
  });
}
createRoot(document.getElementById('root')).render(
  <Router>
    <App />
  </Router>,
);
